<template>
  <div class="flex flex-col gap-3.5 p-5 bg-white shadow-base shadow-black/8 ring-1 ring-inset ring-#f1f1f1 rounded-4.5 relative">
    <div class="flex gap-3.5">
      <img
        class="size-8.75 rounded-2"
        src="/favicon.ico"
        alt=""
      >
      <div>
        <p class="text-tekstovyy text-4.25 font-700 leading-0.8">Заказ № {{ review.last_order.id }}</p>
        <div class="flex gap-1.75 items-center text-seryy text-3 leading-0.8 mt-1">
          <p class="flex gap-1 items-center text-firmennyy font-700">
            <span class="i-custom:tote text-3.5"></span>
            {{ restaurant }}
          </p>
          {{ review.last_order.order_delivery.address }}
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-3 py-3.5 border-y border-black/5">
      <div class="flex items-start gap-1.75">
        <p class="text-tekstovyy text-4.25 font-700 leading-0.8">{{ review.name }}</p>
        <Rating :rating="review.score" />
      </div>
      <p class="text-#455862 text-3.5 leading-1.2">
        {{ review.message }}
      </p>
    </div>
    <div
      v-if="review?.messages.length"
      class="flex items-start gap-1.75"
    >
      <p class="text-tekstovyy text-4.25 font-700 leading-0.8">Ответ ресторана:</p>
      <p class="text-#455862 text-3.5 leading-1.2">
        {{ review?.messages[0].message }}
      </p>
    </div>

    <Button
      v-if="!review.messages.length"
      class="self-start"
      @click="emits('openReview', review)"
    >
      Ответить на отзыв
    </Button>
    <Button
      v-else
      color="green-light"
      class="self-start"
      suffix-icon="i-custom:caret-down"
      @click="$emit('openReview', review)"
    >
      Посмотреть
    </Button>

    <div
      v-if="!review.messages.length"
      class="flex gap-1 items-center absolute right-2.25 top-2.25 px-2.5 py-1.75 text-#F56122 text-3.25 font-500 leading-0.8 bg-#F56122/10 rounded-full"
    >
      <span class="i-custom:clock-countdown text-4.5"></span>
      Ожидает ответа
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Review } from '~/composables/partner/reviews/types/ReviewTypes';

defineProps<{
	isAnswer?: boolean,
  review: Review,
  restaurant?: string;
}>();

const emits = defineEmits<{
	openReview: [Review]
}>();
</script>
