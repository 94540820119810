import type {
  ReviewResponse, GetReviewListRequestType, AddReviewResponce
} from './types/ReviewTypes';

import type { ServerResponseCommonType } from '~/types/commons';

type TransportReviewType = {
    getReviewList: (query: GetReviewListRequestType) => Promise<ReviewResponse | null>;
    addResponceToReview: (body: AddReviewResponce) => Promise<ServerResponseCommonType |null>;
}

export function useReviews (): TransportReviewType {
  const { $api } = useNuxtApp();

  const getReviewList = async (query: GetReviewListRequestType) => {
    const res = await $api<ServerResponseCommonType<ReviewResponse>>('/partner/feedback/getList', {
      query: {
        ...query
      }
    });
    if (res && res.data) {
      return res.data;
    }
    return null;
  };
  const addResponceToReview = async (body: AddReviewResponce) => {
    const res = await $api<ServerResponseCommonType>('/partner/feedback/addMessage', {
      method: 'POST',
      body: {
        ...body
      }
    });
    if (res && res.message === 'Success') {
      return res;
    }
    return null
    ;
  };

  return {
    getReviewList,
    addResponceToReview
  };
}
